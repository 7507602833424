import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'buyers',
    loadChildren: () => import('./buyers/buyers.module').then( m => m.BuyersPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./suppliers/suppliers.module').then( m => m.SuppliersPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'sales-bill',
    loadChildren: () => import('./sales-bill/sales-bill.module').then( m => m.SalesBillPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'purchase-bill',
    loadChildren: () => import('./purchase-bill/purchase-bill.module').then( m => m.PurchaseBillPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'line-accounts',
    loadChildren: () => import('./line-accounts/line-accounts.module').then( m => m.LineAccountsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'tally',
    loadChildren: () => import('./tally/tally.module').then( m => m.TallyPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'purchase-payment',
    loadChildren: () => import('./purchase-payment/purchase-payment.module').then( m => m.PurchasePaymentPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'sales-payment',
    loadChildren: () => import('./sales-payment/sales-payment.module').then( m => m.SalesPaymentPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'create-invoice',
    loadChildren: () => import('./create-invoice/create-invoice.module').then( m => m.CreateInvoicePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'edit-invoice',
    loadChildren: () => import('./edit-invoice/edit-invoice.module').then( m => m.EditInvoicePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'purchase-invoice',
    loadChildren: () => import('./purchase-invoice/purchase-invoice.module').then( m => m.PurchaseInvoicePageModule)
  },
  {
    path: 'create-purchase-invoice',
    loadChildren: () => import('./create-purchase-invoice/create-purchase-invoice.module').then( m => m.CreatePurchaseInvoicePageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'sales-order',
    loadChildren: () => import('./sales-order/sales-order.module').then( m => m.SalesOrderPageModule)
  },
  {
    path: 'create-sales-order',
    loadChildren: () => import('./create-sales-order/create-sales-order.module').then( m => m.CreateSalesOrderPageModule)
  },
  {
    path: 'ledger',
    loadChildren: () => import('./ledger/ledger.module').then( m => m.LedgerPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'expense',
    loadChildren: () => import('./expense/expense.module').then( m => m.ExpensePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
