import { Component, Input, OnInit } from "@angular/core";
import { RouteService } from "../../services/route.service";

@Component({
  selector: "app-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit {
  @Input() url: String;
  @Input() size: String;
  constructor(public routeService: RouteService) {}

  ngOnInit() {}
}
