import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/modules/shared/shared.module';
import { IonicSelectableModule } from 'ionic-selectable';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx'
import EscPosEncoder from 'esc-pos-encoder-ionic';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,
    SharedModule,
    IonicSelectableModule,
    IonicStorageModule.forRoot() ,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  SocialSharing,
  Printer,
  EscPosEncoder, 
  BluetoothSerial,
  AndroidPermissions,
],
  bootstrap: [AppComponent],
})
export class AppModule { }
