import { RouteService } from "./../../services/route.service";
import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-floating-menu",
  templateUrl: "./floating-menu.component.html",
  styleUrls: ["./floating-menu.component.scss"],
})
export class FloatingMenuComponent implements OnInit {
  currentLocation: string;
  @Input() size: String;

  constructor(private router: Router, public routeService: RouteService) {
    this.currentLocation = this.router.url;
  }

  ngOnInit() {}
}
